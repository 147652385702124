import {Injectable} from '@angular/core';
import {Settings} from '@common/core/config/settings.service';
import {slugifyString} from '@common/core/utils/slugify-string';

@Injectable({
    providedIn: 'root'
})
export class CookieService {
    constructor(private settings: Settings) {}

    public get(name: string): string|number {
        const regExp: RegExp = this.getCookieRegExp(this.prefixCookieName(name));
        const result: RegExpExecArray = regExp.exec(document.cookie);
        return result && result[1];
    }

    public set(name: string, value: string|number, expirationDays = 30) {
        const exdate = new Date();
        exdate.setDate(exdate.getDate() + expirationDays);
        document.cookie = `${this.prefixCookieName(name)}=${value}; expires=${exdate.toUTCString()}; path=/;`;
    }

    private getCookieRegExp(name: string): RegExp {
        const escapedName: string = name.replace(/([\[\]\{\}\(\)\|\=\;\+\?\,\.\*\^\$])/ig, '\\$1' );
        return new RegExp( '(?:^' + escapedName + '|;\\s*' + escapedName + ')=(.*?)(?:;|$)', 'g' );
    }

    private prefixCookieName(name: string) {
        return `${slugifyString(this.settings.get('branding.site_name'))}_${name}`;
    }
}
